import React from 'react'
import { css } from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Quote from '../components/quote'
import FooterIndex from '../components/footerIndex'

import moderationImage from '../images/moderation.svg'
import { device } from '../styles/devices'

const TrainingPage = () => (
  <Layout>
    <SEO title="Moderation" />
    <div
      className="grid"
      css={css`
        & > div:not(:last-child) {
          margin-bottom: 50px;

          &:first-child {
            margin-top: 50px;
          }
        }

        @media ${device.tablet} {
          & > div:not(:last-child) {
            margin-bottom: 40px;

            &:first-child {
              margin-top: 50px;
            }
          }
        }
      `}
    >
      <div className="grid-contained">
        <Quote>
          Wir geben Ihrer Veranstaltung einen roten Faden und setzen Akzente.
          Sie haben mehr Freiraum für den Inhalt.
        </Quote>
      </div>

      <div className="grid-contained">
        <div
          css={css`
            & p {
              margin-bottom: 20px;
              font-size: 1.8rem;
              line-height: 33.18px;
              letter-spacing: -0.004em;
            }

            & img {
              margin-top: 50px;
              /* flex: 0 0 600px;
              margin-left: 275px; */
            }

            @media ${device.laptop} {
              display: flex;

              & img {
                margin-left: 100px;
                width: 350px;
                height: 350px;
              }
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <div>
              <h2
                css={css`
                  display: inline-block;
                  position: relative;
                  font-size: 3.2rem;
                  margin-bottom: 40px;
                  background: linear-gradient(0, #fff1c6 24px, transparent 0);
                  &:before {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    left: -18px;
                    background-color: transparent;
                    border-bottom: 24px solid transparent;
                    border-right: 18px solid #fff1c6;
                  }
                  &:after {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    background-color: transparent;
                    border-top: 24px solid transparent;
                    border-left: 18px solid #fff1c6;
                  }
                `}
              >
                Moderation
              </h2>
            </div>
            <p>
              Wir führen neutral durch das Thema der Veranstaltung und bieten
              einen strukturierten Austausch. Dabei sind die Teilnehmenden die
              inhaltlichen Experten. Wir steuern die Diskussion, fördern die
              Kreativität und einen Gruppenprozess bei dem alle Teilnehmenden
              beteiligt sind und gemeinsam die gestellte Aufgabe lösen.
            </p>
          </div>
          <div
            css={css`
              text-align: center;
            `}
          >
            <img src={moderationImage} />
          </div>
        </div>
      </div>

      <div
        className="grid-contained"
        css={css`
          & > div {
            margin: 0 auto;
            width: fit-content;
          }

          & h2 {
            margin-top: 18px;
            font-size: 2.8rem;
          }

          & ul {
            margin-left: 1.6rem;
            list-style: disc;

            & > li {
              line-height: 33.18px;
            }
          }
        `}
      >
        <div>
          <h2>Unser Angebot</h2>
          <ul>
            <li>Workshops</li>
            <li>Konfliktgespräche</li>
            <li>Teammeetings</li>
          </ul>
        </div>
      </div>
    </div>

    <FooterIndex />
  </Layout>
)

export default TrainingPage
